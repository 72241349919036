import "~/globals.js";

import "core-js/stable";
import "regenerator-runtime/runtime";

// Rails functionality
import { Turbo } from "@hotwired/turbo-rails";

// Make accessible for Electron and Mobile adapters
window.Turbo = Turbo;

import * as ActiveStorage from "@rails/activestorage";
import LocalTime from "local-time";
import "trix";
import "@rails/actiontext";
import "~/channels/index.js";
import "~/controllers/index.js";
import "~/polyfills/index.js";

// tom-select
import TomSelect from "tom-select";

// Jumpstart Pro & other Functionality
import.meta.glob("~/src/**/*.{js,ts}", { eager: true });

// Stylesheets
import "~/stylesheets/index.js";

ActiveStorage.start();
LocalTime.start();

// Ahoy tracking
//= require ahoy
import ahoy from "ahoy.js";

ahoy.trackClicks("a, button, input[type=submit]");

// import charts
import "chartkick/chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
Chartkick.adapters[0].library.register(annotationPlugin);
